import React from "react";
import "./CircularLoader.scss";

const CircularLoader = ({ size = 20, color = "#FFFFFF" }) => {
  return (
    <div
      className="circular-loader"
      style={{
        width: size,
        height: size,
        borderWidth: size * 0.12,
        borderColor: "#0096FA",
        borderTopColor: color,
      }}
    ></div>
  );
};

export default CircularLoader;
