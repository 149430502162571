import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Calendar.scss";
import moment from "moment";
import Heading from "../../components/Heading/Heading";
import CaseModal from "../../components/CaseModal/CaseModal";
import Modal from "../../components/CaseModal/Modal";
import SearchResults from "../../components/SearchResults/SearchResults";
import { Context as EventContext } from "../../context/EventContext";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import IconTooltip from "../../components/IconTooltip/IconTooltip";
import ChevronIcon from "../../assets/arrow.svg";
import UploadIcon from "../../assets/UploadIcon.svg";
import DocSperaLoading from "../../components/LoadingIndicator/DocSperaLoading";
import UploadDICOM from "../../components/UploadDICOM/UploadDICOM";

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 5px 10px 30px;

  &:hover {
    background-color: #0096fa28;
    cursor: pointer;
  }
`;

const EventSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  min-width: 50%;
  text-align: left;
`;

const IconSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.75fr;
  gap: 30px;
  min-width: 30%;

  .fa-sync-alt:hover {
    color: grey;
  }
`;

const WeekSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  color: #0096fa;
  margin: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;

  .prev-week,
  .next-week {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 18px;
  }

  .prev-week {
    .chevron {
      transform: rotate(180deg);
    }
  }

  .next-week {
    .chevron {
      transform: rotate(180deg);
    }
  }
`;

function Calendar() {
  const {
    state: eventState,
    setSelectedPhysician,
    setSelectedLocation,
    setQueryValue,
    setSelectedWeek,
    getMoreEvents,
    setSelectedCaseId,
    setIsModalOpen,
    setSelectedCaseDetails,
    setEvents,
    pushCase,
    setIsCaseResent,
    setIsUploadModalOpen,
  } = useContext(EventContext);
  const [viewToggle, setViewToggle] = useState(false);
  const [spinningCaseId, setSpinningCaseId] = useState(null);
  const path = useLocation();
  const query = eventState.queryValue ? `q=${eventState.queryValue}` : "";
  const physician = eventState.selectedPhysician
    ? `&physician_id=${eventState.selectedPhysician}`
    : "";
  const location = eventState.selectedLocation
    ? `&location_name=${eventState.selectedLocation}`
    : "";

  const week =
    !eventState.queryValue &&
    eventState.selectedWeek &&
    eventState.selectedWeek.startDate &&
    eventState.selectedWeek.endDate
      ? `range_start=${eventState.selectedWeek.startDate}&range_end=${eventState.selectedWeek.endDate}`
      : eventState.queryValue &&
        eventState.selectedWeek &&
        eventState.selectedWeek.startDate &&
        eventState.selectedWeek.endDate
      ? `&range_start=${eventState.selectedWeek.startDate}&range_end=${eventState.selectedWeek.endDate}`
      : "";

  const uri = query + week + physician + location;
  const hasMore =
    eventState.events.length < eventState.totalEvents && !!eventState.pageToken;

  useEffect(() => {
    let timeout;

    if (eventState.isCaseResent !== null) {
      timeout = setTimeout(() => {
        setIsCaseResent(null);
      }, 2000); // Reset to "Resend Case" after 2 seconds
    }

    return () => clearTimeout(timeout); // Cleanup timeout
  }, [eventState.isCaseResent]);

  const handlePrevWeek = () => {
    setSelectedWeek({
      startDate: moment(eventState.selectedWeek.startDate, "YYYYMMDDHHmmss")
        .subtract(1, "weeks")
        .format("YYYYMMDDHHmmss"),
      endDate: moment(eventState.selectedWeek.endDate, "YYYYMMDDHHmmss")
        .subtract(1, "weeks")
        .format("YYYYMMDDHHmmss"),
    });
    document.getElementById("scrollable-body").scrollTo(0, 0);
  };

  const handleNextWeek = () => {
    setSelectedWeek({
      startDate: moment(eventState.selectedWeek.startDate, "YYYYMMDDHHmmss")
        .add(1, "weeks")
        .format("YYYYMMDDHHmmss"),
      endDate: moment(eventState.selectedWeek.endDate, "YYYYMMDDHHmmss")
        .add(1, "weeks")
        .format("YYYYMMDDHHmmss"),
    });
    document.getElementById("scrollable-body").scrollTo(0, 0);
  };

  // const handleCurrWeek = () => {
  //   setSelectedWeek({
  //     startDate: moment()
  //       .startOf("week")
  //       .add(1, "days")
  //       .format("YYYYMMDDHHmmss"),
  //     endDate: moment()
  //       .endOf("week")
  //       .subtract(1, "days")
  //       .format("YYYYMMDDHHmmss"),
  //   });
  // };

  //locations/physicians filter dropdown
  const filterDropdown = function (filter) {
    //filter = 0: location, filter = 1: physician
    if (filter) {
      return (
        eventState.physicians.length &&
        eventState.physicians.map((phys) => {
          return {
            value: phys.id,
            name: phys.name,
          };
        })
      );
    }
    return eventState.locations;
  };

  const handleCaseClick = (eventId) => {
    setIsModalOpen(true);
    setSelectedCaseId(eventId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCaseId(null);
    setSelectedCaseDetails();
  };

  let currentDay = "";

  const foundPhysician = (physicianId, providersList) => {
    const physician =
      providersList.length && providersList.find((p) => p.id === physicianId);
    return physician?.name || "";
  };

  return (
    <div className="mt-4">
      <Heading
        setEvents={setEvents}
        filterDropdown={filterDropdown}
        phyFilter={eventState.selectedPhysician}
        setPhyFilter={setSelectedPhysician}
        locFilter={eventState.selectedLocation}
        setLocFilter={setSelectedLocation}
        currentPath={path.pathname}
        searchValue={eventState.queryValue}
        setSearchValue={setQueryValue}
        allEvents={eventState.events}
        dateRange={eventState.selectedWeek}
        setDateRange={setSelectedWeek}
        startDate={eventState.selectedWeek.startDate}
        endDate={eventState.selectedWeek.endDate}
        viewToggle={viewToggle}
        setViewToggle={setViewToggle}
      />
      {eventState.isLoading ? (
        <div className="center-container">
          <DocSperaLoading isDataLoading={false} setPageLoaded={() => {}} />
        </div>
      ) : eventState.queryValue ? (
        <SearchResults
          location={path}
          events={eventState.events}
          pushCase={pushCase}
          hasMore={hasMore}
          getMoreEvents={getMoreEvents}
          paginationUri={uri}
          handleCaseClick={handleCaseClick}
        />
      ) : (
        <>
          <InfiniteScroll
            dataLength={eventState.events.length}
            next={() => getMoreEvents(uri + `&page=${eventState.pageToken}`)}
            hasMore={hasMore}
            scrollableTarget="scrollable-body"
          >
            <div className="weekly-events" id="scrollable-body">
              {eventState.events.map((data) => {
                const eventDay = moment(
                  data.event.time.start,
                  "YYYYMMDDHHmmss"
                ).format("dddd");

                const dayHeader =
                  currentDay !== eventDay ? (
                    <h4 key={eventDay} style={{}}>
                      {eventDay.toUpperCase()}
                    </h4>
                  ) : null;

                currentDay = eventDay;
                return (
                  <div key={data.event.event_id}>
                    {dayHeader}
                    <DataRow
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCaseClick(data.event.event_id);
                      }}
                    >
                      <EventSection>
                        <div>
                          {" "}
                          {moment(
                            data.event.time.start,
                            "YYYYMMDDHHmmss"
                          ).format("M/D/YYYY")}
                        </div>
                        <div style={{ textTransform: "capitalize" }}>
                          {data.event.procedure_metadata.joint_type
                            ? data.event.procedure_metadata.joint_type
                            : "N/A"}
                        </div>
                        <div>
                          {" "}
                          {data.event.patient.first_name &&
                            data.event.patient.first_name[0]}
                          {data.event.patient.last_name &&
                            data.event.patient.last_name[0]}
                        </div>
                        <div>
                          {foundPhysician(
                            data.event.calendar,
                            data.event.attendees.providers
                          )}
                        </div>
                      </EventSection>
                      <IconSection>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconTooltip
                            test={data.event.attachments.length !== 0}
                            text="DICOMs"
                            isDicom={true}
                          />
                          <IconTooltip
                            classname="far fa-lg fa-file-pdf"
                            test={
                              data.event.attachments.length > 0 &&
                              data.event.attachments.some(
                                (file) =>
                                  file.toLowerCase().includes("templated") &&
                                  file.toLowerCase().includes(".dcm")
                              )
                            }
                            text="Templated Images"
                          />
                          <IconTooltip
                            classname="fas fa-lg fa-ruler-vertical"
                            test={
                              data.event.patient.demographics.height ||
                              data.event.patient.demographics.weight
                            }
                            text="Height and Weight"
                          />
                          <IconTooltip
                            classname="far fa-lg fa-clipboard"
                            test={
                              data.event.location.address &&
                              data.event.patient.first_name &&
                              data.event.patient.last_name &&
                              data.event.mrn &&
                              data.event.patient.demographics.gender !== "0" &&
                              data.event.patient.dob !== 0
                            }
                            text="Case Details"
                          />
                        </div>
                        <IconTooltip
                          classname={`fas fa-lg ${
                            spinningCaseId === data.event.event_id &&
                            eventState.isCaseResent
                              ? "fa-check green"
                              : "fa-sync-alt"
                          } ${
                            spinningCaseId === data.event.event_id &&
                            eventState.isResendingCase &&
                            "spin"
                          }`}
                          test={true}
                          text={
                            spinningCaseId === data.event.event_id &&
                            eventState.isCaseResent
                              ? "Case Resent"
                              : "Resend Case"
                          }
                          resendCase
                          caseResent={
                            spinningCaseId === data.event.event_id &&
                            eventState.isCaseResent
                          }
                          onClickFunction={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            pushCase(data.event.event_id);
                            setSpinningCaseId(data.event.event_id);
                          }}
                        />
                      </IconSection>
                    </DataRow>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
          <WeekSection>
            <div className="prev-week" onClick={() => handlePrevWeek()}>
              <Icon
                customClass="chevron"
                url={ChevronIcon}
                height="12px"
                width="12px"
              />
              <span>Previous Week</span>
            </div>
            <div className="next-week" onClick={() => handleNextWeek()}>
              <span>Next Week</span>
              <Icon
                className="chevron"
                url={ChevronIcon}
                height="12px"
                width="12px"
              />
            </div>
          </WeekSection>
        </>
      )}

      {eventState.isModalOpen && (
        <Modal
          maxWidth="75%"
          content={
            <>
              <CaseModal pushCase={pushCase} closeModal={handleCloseModal} />
              {eventState.isUploadModalOpen && (
                <Modal
                  maxWidth="75%"
                  content={<UploadDICOM />}
                  title="Upload DICOMS"
                  toggleModal={() => setIsUploadModalOpen(false)}
                />
              )}
            </>
          }
          toggleModal={handleCloseModal}
          title={`Case #${eventState.selectedCaseId}`}
          showtimesIcon
          icons={[
            {
              url: UploadIcon,
              clickedFunction: () => setIsUploadModalOpen(true),
              alt: "Upload Icon",
            },
          ]}
        />
      )}
    </div>
  );
}

export default Calendar;
