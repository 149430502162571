import React, { useEffect, useState, useContext } from "react";
import { Context as EventContext } from "../../context/EventContext";
import moment from "moment";
import "./SearchResults.scss";
import CaseModal from "../CaseModal/CaseModal";
import InfiniteScroll from "react-infinite-scroll-component";
import IconTooltip from "../IconTooltip/IconTooltip";
import DicomIcon from "../../assets/DICOM.svg";
import MissingDicomIcon from "../../assets/DICOM-missing.svg";

function SearchResults(props) {
  const { state: eventState } = useContext(EventContext);
  const [isScroll, setIsScroll] = useState(false);
  const [spinningCaseId, setSpinningCaseId] = useState(null);

  useEffect(() => {
    if (document.getElementById("search-scrollable-body")) {
      if (
        document.getElementById("search-scrollable-body").scrollHeight >
        document.getElementById("search-scrollable-body").clientHeight
      ) {
        return setIsScroll(true);
      }
      setIsScroll(false);
    }
  }, [
    document.getElementById("search-scrollable-body"),
    eventState.selectedLocation,
    eventState.selectedPhysician,
    eventState.queryValue,
    eventState.selectedWeek,
  ]);

  let row = props.events.map((data, idx) => (
    <tr
      className="Calendar-list"
      key={`${data.event.event_id}${idx}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.handleCaseClick(data.event.event_id);
      }}
    >
      <td style={{ minWidth: 100 }}>
        {moment(data.event.time.start, "YYYYMMDDHHmmss").format("M/DD/YY")}
      </td>
      <td style={{ minWidth: 100 }}>
        {moment(data.event.time.start, "YYYYMMDDHHmmss").format("h:mma")}
      </td>
      <td style={{ minWidth: 150 }}>{data.event.event_id}</td>
      <td style={{ minWidth: 150, wordBreak: "break-word" }}>
        {data.event.attendees.providers[0].name}
      </td>
      <td style={{ minWidth: 200, wordBreak: "break-word" }}>
        {data.event.location.address || (
          <span style={{ color: "#646464", fontStyle: "italic" }}>
            Unknown!
          </span>
        )}
      </td>
      <td style={{ minWidth: 400 }}>
        <div className="row align-items-center">
          <div className="col col-sm-2" style={{ textTransform: "capitalize" }}>
            {data.event.procedure_metadata.joint_type
              ? data.event.procedure_metadata.joint_type
              : "N/A"}
          </div>
          <div className="col col-sm-2">
            {data.event.patient.first_name && data.event.patient.first_name[0]}
            {data.event.patient.last_name && data.event.patient.last_name[0]}
          </div>
          <div className="col col-sm-2">
            <IconTooltip
              test={data.event.attachments.length !== 0}
              text="DICOMs"
              isDicom={true}
            />
          </div>
          <div className="col col-sm-2">
            <IconTooltip
              classname="far fa-lg fa-file-pdf"
              test={data.event.attachments.some((file) =>
                file.toLowerCase().includes("templated")
              )}
              text="Templated Images"
              searchedResults
            />
          </div>
          <div className="col col-sm-2">
            <IconTooltip
              classname="fas fa-lg fa-ruler-vertical"
              test={
                data.event.patient.demographics.height ||
                data.event.patient.demographics.weight
              }
              text="Height and Weight"
              searchedResults
            />
          </div>
          <div className="col col-sm-2">
            <IconTooltip
              classname="far fa-lg fa-clipboard"
              test={
                data.event.location.address &&
                data.event.patient.first_name &&
                data.event.patient.last_name &&
                data.event.mrn &&
                data.event.patient.demographics.gender !== "0" &&
                data.event.patient.dob !== 0
              }
              text="Case Details"
              searchedResults
            />
          </div>
        </div>
      </td>
      <td>
        <IconTooltip
          styles={{ margin: "auto" }}
          classname={`fas fa-lg ${
            spinningCaseId === data.event.event_id && eventState.isCaseResent
              ? "fa-check green"
              : "fa-sync-alt"
          } ${
            spinningCaseId === data.event.event_id &&
            eventState.isResendingCase &&
            "spin"
          }`}
          test={true}
          text={
            spinningCaseId === data.event.event_id && eventState.isCaseResent
              ? "Case Resent"
              : "Resend Case"
          }
          resendCase
          caseResent={
            spinningCaseId === data.event.event_id && eventState.isCaseResent
          }
          onClickFunction={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.pushCase(data.event.event_id);
            setSpinningCaseId(data.event.event_id);
          }}
        />
      </td>
    </tr>
  ));

  //display search result from missing items w/ no filters
  let missingRow = props.events.map((data, idx) => (
    <tr className="Missing-list" key={idx}>
      <td
        width="10%"
        data-bs-toggle="modal"
        data-bs-target={`#m${data.event.event_id}`}
      >
        {moment(data.event.time.start, "YYYYMMDDHHmmss").format("M/DD/YY")}
      </td>
      <td
        width="10%"
        data-bs-toggle="modal"
        data-bs-target={`#m${data.event.event_id}`}
      >
        {data.event.attendees.providers.map((p) => p.name)}
      </td>
      <td
        width="10%"
        data-bs-toggle="modal"
        data-bs-target={`#m${data.event.event_id}`}
      >
        {data.event.event_id}
      </td>
      <td
        width="5%"
        data-bs-toggle="modal"
        data-bs-target={`#m${data.event.event_id}`}
      >
        {data.event.patient.first_name && data.event.patient.first_name[0]}
        {data.event.patient.last_name && data.event.patient.last_name[0]}
      </td>
      <td
        width="40%"
        className=""
        data-bs-toggle="modal"
        data-bs-target={`#m${data.event.event_id}`}
      >
        <div className="row justify-content-center align-items-start">
          <div className="col col-md-3">
            {data.event.attachments.length !== 0 ? (
              <img width="20px" src={DicomIcon} alt="dicom icon" />
            ) : (
              <img
                width="20px"
                src={MissingDicomIcon}
                alt="dicom-missing icon"
              />
            )}
          </div>
          <div className="col col-md-3">
            <i
              className="far fa-lg fa-file-pdf"
              style={{
                color: data.event.attachments.some((file) =>
                  file.toLowerCase().includes("templated")
                )
                  ? "black"
                  : "red",
              }}
            ></i>
          </div>
          <div className="col col-md-3">
            <i
              className="fas fa-ruler-vertical"
              style={{
                color:
                  data.event.patient.demographics.height ||
                  data.event.patient.demographics.weight
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
          <div className="col col-md-3">
            <i
              className="far fa-lg fa-clipboard"
              style={{
                color:
                  data.event.location.address &&
                  data.event.patient.first_name !== "" &&
                  data.event.patient.last_name !== "" &&
                  data.event.patient.mrn &&
                  data.event.patient.demographics.gender !== "0" &&
                  data.event.patient.dob !== 0
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
        </div>
      </td>
      <td
        width="5%"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.pushCase(data.event.event_id);
        }}
      >
        <i className={`fas fa-sync-alt `} />
      </td>
    </tr>
  ));

  return props.location.pathname === "/missing" ? (
    <div className="container-fluid table-responsive">
      <table className="table table-md table-bordered mt-4">
        <thead className="Missing-thead">
          <tr>
            <th>Date</th>
            <th>Physician</th>
            <th>Case ID</th>
            <th>Patient</th>
            <th>
              <div className="row justify-content-center align-items-start">
                <div className="col col-md-3">Dicoms</div>
                <div className="col col-md-3">Templated Img</div>
                <div className="col col-md-3">Height/Weight</div>
                <div className="col col-md-3">Case Details</div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{missingRow}</tbody>
      </table>
      {props.events.map((event) => (
        <CaseModal event={event} key={event.event_id} />
      ))}
    </div>
  ) : (
    <div className="container-fluid ">
      <InfiniteScroll
        dataLength={props.events.length}
        next={() => props.getMoreEvents(props.paginationUri)}
        hasMore={props.hasMore}
        scrollableTarget="search-scrollable-body"
      >
        <table
          id="search-results-table"
          className="table table-sm table-responsive mt-4"
        >
          <thead className="SearchResults-thead">
            <tr
              style={{
                gridTemplateColumns: `0.75fr 0.75fr 0.75fr 1.5fr 1.5fr 3fr 0.5fr ${
                  props.hasMore || isScroll ? "15px" : ""
                } auto`,
              }}
            >
              <th style={{ minWidth: 100 }}>Date</th>
              <th style={{ minWidth: 100 }}>Start Time</th>
              <th style={{ minWidth: 150 }}>Case ID</th>
              <th style={{ minWidth: 150 }}>Physician</th>
              <th style={{ minWidth: 200 }}>Location</th>
              <th style={{ minWidth: 400 }}></th>
              <th style={{ minWidth: 40 }}></th>
              {props.hasMore || props.events.length > 20 ? (
                <th style={{ paddingRight: "9px" }}></th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody id="search-scrollable-body">{row}</tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}

export default SearchResults;
