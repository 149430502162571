import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";

import { Context as EventContext } from "../../context/EventContext";
import moment from "moment";
import DocSpearLogo1 from "../../assets/docspera_logo_1.svg";

const NavBar = () => {
  const {
    state: eventState,
    getPhysicians,
    getPhysicianLocations,
    queryEvents,
    setSelectedWeek,
    setQueryValue,
    setSelectedLocation,
  } = useContext(EventContext);

  // get list of physicians
  useEffect(() => {
    getPhysicians();
  }, []);

  // get list of physician locations
  useEffect(() => {
    if (eventState.selectedPhysician) {
      setSelectedLocation(null);
      getPhysicianLocations(eventState.selectedPhysician);
    }
  }, [eventState.selectedPhysician]);

  // query cases when query value provided
  useEffect(() => {
    if (eventState.queryValue) {
      const query = `q=${eventState.queryValue}`;
      const physician = eventState.selectedPhysician
        ? `&physician_id=${eventState.selectedPhysician}`
        : "";
      const location = eventState.selectedLocation
        ? `&location_name=${eventState.selectedLocation}`
        : "";

      const week =
        eventState.selectedWeek &&
        eventState.selectedWeek.startDate &&
        eventState.selectedWeek.endDate
          ? `&range_start=${eventState.selectedWeek.startDate}&range_end=${eventState.selectedWeek.endDate}`
          : "";

      const uri = query + physician + location + week;
      queryEvents(uri);
    }
  }, [
    eventState.queryValue,
    eventState.selectedPhysician,
    eventState.selectedLocation,
    eventState.selectedWeek,
  ]);

  // query cases when daterange provided
  useEffect(() => {
    if (
      !eventState.queryValue &&
      (eventState.selectedWeek.startDate || eventState.selectedWeek.endDate)
    ) {
      const week =
        eventState.selectedWeek &&
        eventState.selectedWeek.startDate &&
        eventState.selectedWeek.endDate
          ? `range_start=${eventState.selectedWeek.startDate}&range_end=${eventState.selectedWeek.endDate}`
          : "";
      const physician = eventState.selectedPhysician
        ? `&physician_id=${eventState.selectedPhysician}`
        : "";
      const location = eventState.selectedLocation
        ? `&location_name=${eventState.selectedLocation}`
        : "";

      const uri = week + physician + location;
      queryEvents(uri);
    }
  }, [
    eventState.selectedWeek,
    eventState.selectedPhysician,
    eventState.selectedLocation,
  ]);

  useEffect(() => {
    if (!eventState.queryValue) {
      setSelectedWeek({
        startDate: moment()
          .startOf("week")
          .add(1, "days")
          .format("YYYYMMDDHHmmss"),
        endDate: moment()
          .endOf("week")
          .subtract(1, "days")
          .format("YYYYMMDDHHmmss"),
      });
      setQueryValue(null);
    }
  }, [eventState.queryValue]);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <img className="Navbar-logo" src={DocSpearLogo1} alt="docspera-logo" />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "nav-link selected" : "nav-link"
                }
              >
                ACM Dashboard
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/status" className="nav-link" activeClassName="selected">Integration Status</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink to="/locnpro" className="nav-link">Locations &amp; Providers </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                to="/missing"
                className="nav-link position-relative"
                activeClassName="selected"
              >
                Missing
                <div className="notification">{props.missingItems.length}</div>
              </NavLink>
            </li> */}
          </ul>
          {/* <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/login" className="nav-link" activeClassName="selected">Login</NavLink>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
