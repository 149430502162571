import styled from "styled-components";
import SearchIcon from "../../assets/SearchIcon.svg";

const StyledInput = styled.div`
  // display: flex;
  height: fit-content;
  // width: 100%;
  min-width: fit-content;

  .title {
    color: #141414;
    font-size: 0.875rem;
    text-align: left;
    margin-bottom: 5px;
    font-weight: 500;
  }

  span {
    color: "#FF0000";
  }

  .search-box {
    background-color: #fff;
    border: 1px solid #0096fa;
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
  }

  input {
    position: relative;
    transition: none;
    color: ${(props) => (props.$disabled ? "#d5d5d5" : "#141414")};
    font-weight: 500;
    font-size: 16px;
    border: none;
    line-height: 10px;
    display: inline-block;
    width: 100%;
  }

  input:focus {
    animation: none;
    border: none;
  }

  input:focus-visible {
    outline: none;
  }
`;

export function SearchBar({
  grabbedValue,
  disabled,
  placeholder,
  styles,
  valueChangeHandler,
}) {
  return (
    <>
      <StyledInput $disabled={disabled || false} style={{ ...styles }}>
        <div className="search-box">
          <img src={SearchIcon} alt="search" height={20} width={20} />
          <input
            value={grabbedValue || ""}
            onChange={valueChangeHandler}
            disabled={disabled}
            placeholder={placeholder}
          />
        </div>
      </StyledInput>
    </>
  );
}
