export const AlomEncodeType2 = (Number) => {
  if (typeof Number === "number" && Number % 1 === 0 && Number > 0) {
    var NumberArray = Number.toString().split("");
    // eslint-disable-next-line
    var SumOfNumber = eval(NumberArray.join("+"));
    var SumOfLength = Number.toString().length;
    while (SumOfNumber > 9) {
      var DigitNum = SumOfNumber.toString().split("");
      // eslint-disable-next-line
      SumOfNumber = eval(DigitNum.join("+"));
    }
    while (SumOfLength > 9) {
      var DigitLength = SumOfLength.toString().split("");
      // eslint-disable-next-line
      SumOfLength = eval(DigitLength.join("+"));
    }
    return Number + "" + SumOfLength + "" + SumOfNumber;
  } else {
    return false;
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      let value = c.substring(name.length, c.length);
      return value;
    }
  }
  return "";
};

export const checkIsDicom = (fileName) => {
  const regex = /\.(dcm|dicom)$/i;
  return regex.test(fileName);
};
