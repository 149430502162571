import styled from "styled-components";
import DicomIcon from "../../assets/DICOM.svg";
import HoveredDICOM from "../../assets/BlueDICOM.svg";
import MissingDicomIcon from "../../assets/DICOM-missing.svg";

const MainItem = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;

  &.dicom-item {
    .hovered {
      display: none;
    }

    .static {
      display: block;
    }

    &:hover {
      .hovered {
        display: block;
      }

      .static {
        display: none;
      }
    }
  }

  i.black {
    color: black;
  }

  i.missing {
    color: #ff0000;
  }

  &:hover {
    .black {
      color: #0096fa !important;
    }

    .item-text {
      display: block;
    }
  }
`;

const HoverItem = styled.div`
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 1rem;
  position: absolute;
  display: none;
  bottom: 10px;
  left: 12px;
  text-wrap: nowrap;
  z-index: 1;

  .red {
    color: #ff0000;
  }
`;

export default function IconTooltip({
  classname,
  test,
  text,
  isDicom,
  onClickFunction,
  resendCase,
  styles,
  caseResent,
}) {
  return (
    <MainItem
      className={isDicom ? "dicom-item" : ""}
      onClick={onClickFunction}
      style={styles}
    >
      {!isDicom ? (
        <i
          className={`${classname} ${
            caseResent ? "" : test ? `black` : `missing`
          }`}
        ></i>
      ) : test ? (
        <>
          <img
            width="20px"
            src={DicomIcon}
            alt="dicom icon"
            className="static"
          />
          <img
            width="20px"
            src={HoveredDICOM}
            alt="dicom icon"
            className="hovered"
          />
        </>
      ) : (
        <img width="20px" src={MissingDicomIcon} alt="dicom icon" />
      )}
      <HoverItem
        className="item-text"
        style={
          test ? (resendCase ? { left: "-95px" } : {}) : { color: "#ff0000" }
        }
      >
        {`${text} ${test ? "" : "Missing"}`}
      </HoverItem>
    </MainItem>
  );
}
