import instance from "../dataAccess/docspera";
import createDataContext from "./createDataContext";
import config from "./../config";

let initialState = {
  config: config,
  isNavMenuOpen: true,
  isUserMenuOpen: false,
  isIdle: false,
  currentView: "",
  showNotification: false,
  showAlert: false,
  alertTitle: null,
  alertBody: null,
  alertCancelHandler: null,
  alertCancelText: null,
  alertOKHandler: null,
  alertOKText: null,
  notification: "",
  totalInactiveMinutes: null,
  counts: {},
  unreadMessagesCount: null,
  errorMessage: "",
  statusMessage: "",
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case "SetConfig":
      return {
        ...state,
        config: action.payload,
      };
    case "ToggleNavMenu":
      return {
        ...state,
        isNavMenuOpen: !state.isNavMenuOpen,
      };
    case "SetCurrentView":
      return {
        ...state,
        currentView: action.payload,
      };
    case "SetNotification":
      return {
        ...state,
        showNotification: true,
        notification: action.payload,
      };
    case "ClearNotification":
      return {
        ...state,
        showNotification: false,
        notification: "",
      };
    case "OpenAlert":
      return {
        ...state,
        showAlert: true,
        alertTitle: action.payload.alertTitle,
        alertBody: action.payload.alertBody,
        alertCancelHandler: action.payload.alertCancelHandler,
        alertCancelText: action.payload.alertCancelText,
        alertOKHandler: action.payload.alertOKHandler,
        alertOKText: action.payload.alertOKText,
      };
    case "CloseAlert":
      return {
        ...state,
        showAlert: false,
        alertTitle: null,
        alertBody: null,
        alertCancelText: null,
        alertOKHandler: null,
        alertOKText: null,
      };
    case "ToggleUserMenu":
      return {
        ...state,
        isUserMenuOpen: action.payload,
      };
    case "ShowInactivity":
      return {
        ...state,
        isIdle: true,
      };
    case "HideInactivity":
      return {
        ...state,
        isIdle: false,
      };
    case "SetTotalInactiveMinutes":
      return {
        ...state,
        totalInactiveMinutes: action.payload,
      };
    case "GetCounts":
      return {
        ...state,
        counts: action.payload,
      };
    case "GetUnreadMessagesCount":
      return {
        ...state,
        unreadMessagesCount: action.payload,
      };
    case "error":
      console.log("error :: ", action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: "",
      };
    default:
      return state;
  }
};

const getConfig = (dispatch) => async () => {
  fetch("/config.json")
    .then((res) => res.json())
    .then(
      (response) => {
        if (response) {
          dispatch({ type: "SetConfig", payload: response });
        }
      },
      (error) => {
        console.log("error getting config"); // fallback for Dev environment
        if (config) {
          dispatch({ type: "SetConfig", payload: config });
        }
      },
    );
};

const toggleNavMenu = (dispatch) => async () => {
  dispatch({ type: "ToggleNavMenu" });
};

const setCurrentView = (dispatch) => (endpoint) => {
  dispatch({ type: "SetCurrentView", payload: endpoint });
};

const setNotification = (dispatch) => async (notificationText) => {
  if (notificationText) {
    dispatch({ type: "SetNotification", payload: notificationText });
    setTimeout(() => {
      dispatch({ type: "ClearNotification" });
    }, 10000);
  }
};

const clearNotification = (dispatch) => () => {
  dispatch({ type: "ClearNotification" });
};

/*
  inputObj: {
    title: string (optional),
    body: string (optional),
    cancelText: string (optional, button will say 'Cancel' by default),
    okHandler: function (optional for rendering Alert component, but required to have an OK button appear),
    okText: string (optional for rendering Alert component, but required to have an OK button appear)
  }
*/
const openAlert = (dispatch) => (inputObj) => {
  const payload = {
    alertTitle: inputObj.title ? inputObj.title : null,
    alertBody: inputObj.body ? inputObj.body : null,
    alertCancelHandler: inputObj.cancelHandler ? inputObj.cancelHandler : null,
    alertCancelText: inputObj.cancelText ? inputObj.cancelText : "Cancel",
    alertOKHandler: inputObj.okHandler ? inputObj.okHandler : null,
    alertOKText: inputObj.okText ? inputObj.okText : null,
  };

  dispatch({ type: "OpenAlert", payload: payload });
};

const closeAlert = (dispatch) => () => {
  dispatch({ type: "CloseAlert" });
};

const toggleUserMenu = (dispatch) => (boolean) => {
  dispatch({ type: "ToggleUserMenu", payload: boolean });
};

const showInactivity = (dispatch) => () => {
  dispatch({ type: "ShowInactivity" });
};

const hideInactivity = (dispatch) => () => {
  dispatch({ type: "HideInactivity" });
};

const setTotalInactiveMinutes = (dispatch) => (total) => {
  if (total && total > 0) {
    dispatch({ type: "SetTotalInactiveMinutes", payload: total });
  }
};

const getCounts = (dispatch) => async (url, userId) => {
  if (!url) {
    return dispatch({ type: "Error", payload: "Error from getCounts: no url" });
  }

  if (url && userId) {
    const request = {
      id: parseInt(userId) > 0 ? parseInt(userId) : userId,
    };

    try {
      const response = await instance.post(url, request);

      if (!response.data) {
        return dispatch({ type: "Error", payload: `no response from ${url}` });
      } else {
        if (
          response.data &&
          response.data.status === "Success" &&
          response.data.counts !== undefined
        ) {
          dispatch({ type: "GetCounts", payload: response.data.counts });
          if (
            response.data.counts.messages !== undefined &&
            response.data.counts.messages.unread_threads !== undefined
          ) {
            dispatch({
              type: "GetUnreadMessagesCount",
              payload: response.data.counts.messages.unread_threads,
            });
          }
        }
        return;
      }
    } catch (error) {
      return dispatch({ type: "Error", payload: error.message });
    }
  }

  return;
};

export const { Provider, Context } = createDataContext(
  globalReducer,
  {
    getConfig,
    toggleNavMenu,
    setCurrentView,
    setNotification,
    clearNotification,
    openAlert,
    closeAlert,
    toggleUserMenu,
    showInactivity,
    hideInactivity,
    setTotalInactiveMinutes,
    getCounts,
  },
  initialState,
);
