"use client";

import { useEffect, useState } from "react";

export const useDropdown = (props) => {
  const { selectedOption, disabled, customToggle } = props;
  let { optionLists } = props;
  const [isToggled, setIsToggled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchedOptions, setSearchedOptions] = useState(optionLists);
  const [viewOption, setViewOption] = useState(selectedOption);

  useEffect(() => {
    if (selectedOption) {
      const findName =
        optionLists.length > 0 &&
        optionLists.find(
          (option) =>
            (option.value || option).toString() === selectedOption.toString()
        );
      if (findName) {
        return setViewOption(findName.name);
      }
    }
    setViewOption(selectedOption);
  }, [selectedOption]);

  // useEffect(() => {
  //   setSearchedOptions(optionLists);
  // }, [optionLists]);

  const IsOpened = () => {
    if (!disabled) {
      if (isToggled) {
        // if (selectedOption) {
        //   setHasError(true);
        // }
        if (customToggle) {
          customToggle();
        }
        setIsToggled(false);
      } else {
        setIsToggled(true);
      }
    } else {
      setIsToggled(false);
    }
  };

  const SetOption = (name, value) => {
    console.log("set", name);

    if (hasError) {
      setHasError(false);
    }
    setIsToggled(false);
  };

  return {
    isToggled,
    hasError,
    IsOpened,
    viewOption,
    SetOption,
    options: optionLists,
    setIsToggled,
    setViewOption,
    setSearchedOptions,
    searchedOptions,
  };
};
