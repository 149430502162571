import React, { useContext, useState } from "react";
import { Context as EventContext } from "../../context/EventContext";
import "./UploadDICOM.scss";
import DocSperaButton from "../DocSperaButton/DocSperaButton";
import { useDropzone } from "react-dropzone";
import DicomIcon from "../../assets/BlueDICOM.svg";
import { checkIsDicom } from "../../helpers";
import toast from "react-hot-toast";

function UploadDICOM() {
  const {
    state: eventState,
    setIsUploadModalOpen,
    getPresignedUrl,
    uploadFile,
    getUploadFileStatus,
    setUploadSuccess,
  } = useContext(EventContext);
  const [dicoms, setDicoms] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    maxSize: 2048000000,
    onDropRejected: () => {
      toast("File(s) too large");
    },
    onDrop: (files) => {
      setDicoms(files);
    },
  });

  const copyFile = (originalFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result;
        if (arrayBuffer) {
          const newFile = new File([arrayBuffer], originalFile.name, {
            type: checkIsDicom(originalFile.name)
              ? "application/dicom"
              : originalFile.type,
          });
          resolve(newFile);
        }
        resolve(originalFile);
      };

      reader.onerror = () => {
        console.log("Error reading file");
        resolve(originalFile);
      };

      reader.readAsArrayBuffer(originalFile);
    });
  };

  const handleUploadDicoms = async () => {
    setIsUploading(true);
    try {
      const uploadResp = await Promise.all(
        dicoms.map(async (uploadedFile) => {
          const fileObj = {
            folder: 0,
            hidden: 1,
            name: uploadedFile.name,
            phi: 0,
          };

          const { uri, file } = await getPresignedUrl(
            eventState.selectedCaseId,
            fileObj
          );

          if (!uri) toast("File upload error: no presigned url");

          const fileToUpload = await copyFile(uploadedFile);
          console.log(fileToUpload);
          const fileUploadedResp = await uploadFile(uri, fileToUpload);

          if (
            fileUploadedResp &&
            typeof fileUploadedResp !== "string" &&
            fileUploadedResp.status === 200
          ) {
            file.status = "completed";
            const statusUploadResponse = await getUploadFileStatus(
              eventState.selectedCaseId,
              file
            );

            console.log(statusUploadResponse);
            if (
              statusUploadResponse &&
              statusUploadResponse.status === "success"
            ) {
              toast("File upload successful");
              statusUploadResponse.file.name = file.name;
              statusUploadResponse.file.type = checkIsDicom(file.name)
                ? "DICOM"
                : file.type;
              return statusUploadResponse;
            }
          }
        })
      );

      if (!uploadResp.includes(undefined)) {
        setUploadSuccess(true);
      }

      if (uploadResp) {
        setIsUploading(false);
        setIsUploadModalOpen(false);
        setDicoms([]);
      }
    } catch (error) {
      setIsUploading(false);
      toast("File upload error: failed to upload file");
      return `Error: ${error.message}`;
    }
  };

  return (
    <div className="upload-modal">
      {dicoms.length === 0 ? (
        <>
          <div className="upload-dicom-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag and Drop DICOMS</p>
              <DocSperaButton
                className="secondary"
                label="or Select from yout Computer"
                customPadding={".25rem .5rem"}
                onPress={() => open()}
              />
            </div>
          </div>
          <div className="modal-footer">
            <DocSperaButton
              className="secondary"
              label="Cancel"
              customPadding={".25rem .5rem"}
              onPress={() => setIsUploadModalOpen(false)}
            />
          </div>
        </>
      ) : (
        <>
          <div className="dicom-container">
            {dicoms.map((dicom) => (
              <div key={dicom.path} className="dicom-file">
                <img width="25px" src={DicomIcon} alt="dicom-icon" />
                <div>{dicom.name}</div>
              </div>
            ))}
          </div>
          <p>
            The DICOM(s) will be associated with case number #
            {eventState.selectedCaseId}. Do you want to confirm uploading the
            DICOM(s)?
          </p>
          <div className="modal-footer">
            <DocSperaButton
              className="secondary"
              label="Cancel"
              customPadding={".25rem .5rem"}
              onPress={() => setDicoms([])}
            />
            <DocSperaButton
              className="primary"
              label={isUploading ? "Uploading..." : "Confirm"}
              customPadding={".25rem .5rem"}
              onPress={() => handleUploadDicoms()}
              disabled={isUploading}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UploadDICOM;
