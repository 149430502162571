import React from "react";
import "./Heading.scss";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DocSperaDropdown from "../Dropdown/DocSperaDropdown";
import { SearchBar } from "../SearchBar/SearchBar";
import DocSperaButton from "../DocSperaButton/DocSperaButton";
import CloseIcon from "../../assets/Close.svg";

const Heading = (props) => {
  const handleQuery = (e) => {
    props.setSearchValue(e.target.value);
  };

  const handleQuery2 = (e) => {
    props.setSearchValue(e.target.value);
  };

  const handleSubmit2 = () => {
    let foundEvent = props.missingItems.filter(
      (event) => event.event_id === parseInt(props.searchValue)
    );
    props.setIsLoadedQuery(true);
    props.setQuery(foundEvent);
  };

  //clear all search and filters
  const clearFilters = () => {
    props.setEvents([]);
    props.setSearchValue(null);
    props.setLocFilter(null);
    props.setPhyFilter(null);
    props.setDateRange({
      startDate: moment()
        .startOf("week")
        .add(1, "days")
        .format("YYYYMMDDHHmmss"),
      endDate: moment()
        .endOf("week")
        .subtract(1, "days")
        .format("YYYYMMDDHHmmss"),
    });
  };

  const handleClearDateRange = () => {
    props.setDateRange({
      startDate: null,
      endDate: null,
    });
  };

  let heading =
    props.currentPath === "/" ? (
      <div className="alignment">
        {props.searchValue ? (
          <h2>
            Search: <span className="Heading-text">{props.searchValue}</span>
          </h2>
        ) : (
          <h2>
            Week of{" "}
            <span className="Heading-text">
              {moment(props.startDate, "YYYYMMDDHHmmss").format("M/D/YY")} -{" "}
              {moment(props.endDate, "YYYYMMDDHHmmss").format("M/D/YY")}
            </span>
          </h2>
        )}

        <div className="item-align">
          <div style={{ width: "350px" }}>
            <p className="Heading-guide">Search</p>
            <SearchBar
              grabbedValue={props.searchValue}
              valueChangeHandler={handleQuery}
            />
          </div>
          <div style={{ width: "180px" }} className="ms-2">
            <DocSperaDropdown
              name="physician"
              dropdownTitle="Physician"
              selectedOption={props.phyFilter || "All"}
              optionLists={props.filterDropdown(1)}
              optionSelectHandler={(name, value) => {
                props.setPhyFilter(value);
              }}
              showViewOption={true}
              disabled={
                !props.searchValue && (!props.startDate || !props.endDate)
              }
            />
          </div>
          <div style={{ width: "180px" }} className="ms-2">
            <DocSperaDropdown
              name="location"
              dropdownTitle="Location"
              selectedOption={props.locFilter || "All"}
              optionLists={["All", ...props.filterDropdown(0)]}
              optionSelectHandler={(name, value) => {
                props.setLocFilter(value);
              }}
              disabled={!!!props.phyFilter}
            />
          </div>
          <div style={{ width: "180px" }} className="ms-2">
            <DocSperaDropdown
              name="week"
              dropdownTitle="Week"
              selectedOption={props.startDate}
              optionSelectHandler={(value, name) => {
                const startOfWeek = moment(value)
                  .startOf("week")
                  .add(1, "days")
                  .format("YYYYMMDDHHmmss");
                const endOfWeek = moment(value)
                  .endOf("week")
                  .subtract(1, "days")
                  .format("YYYYMMDDHHmmss");
                props.setDateRange({
                  startDate: startOfWeek,
                  endDate: endOfWeek,
                });
              }}
              handleClear={props.searchValue ? handleClearDateRange : undefined}
            />
          </div>
          <div className="ms-2 Heading-clear">
            <DocSperaButton
              className="primary"
              label="Clear Filters"
              onPress={clearFilters}
              icon={CloseIcon}
              height="15px"
              width="15px"
              disabled={
                !props.searchValue && !props.phyFilter && !props.locFilter
              }
            />
          </div>
        </div>
      </div>
    ) : props.currentPath === "/status" ? (
      <div className="alignment">
        <h2>Integration Status</h2>
      </div>
    ) : props.currentPath === "/locnpro" ? (
      <div className="alignment">
        <h2>Locations &amp; Providers</h2>
      </div>
    ) : (
      <div className="alignment">
        <h2>Missing DICOMS &amp; Biometrics</h2>
        <div className="item-align">
          <div style={{ width: "180px" }}>
            <DocSperaDropdown
              name="missing"
              dropdownTitle="Missing Item"
              selectedOption={props.missingFilter}
              optionLists={props.missingDropdown}
              optionSelectHandler={(name, value) => {
                props.setMissingFilter(value);
              }}
              showViewOption={true}
            />
          </div>

          <div className="ms-3">
            <p className="Heading-guide">Search</p>
            <SearchBar
              grabbedValue={props.searchValue}
              valueChangeHandler={handleQuery2}
              placeholder="Search by event id"
              onKeyPress={handleSubmit2}
            />
          </div>
          <div className="ms-1">
            <button
              type="button"
              className="btn fas fa-search Heading-search"
              onClick={handleSubmit2}
            />
          </div>

          <div className="ms-3" style={{ width: "180px" }}>
            <DocSperaDropdown
              name="physician"
              dropdownTitle="Physician"
              selectedOption={
                props.phyFilter.length > 0
                  ? props.phyFilter.join(",")
                  : "Select Physician"
              }
              optionLists={props.filterDropdown(1)}
              optionSelectHandler={(name, value) => {
                if (props.phyFilter.includes(value)) {
                  props.setPhyFilter(
                    props.phyFilter.filter((phys) => phys !== value)
                  );
                } else {
                  props.setPhyFilter([...props.phyFilter, value]);
                }
              }}
            />
          </div>
        </div>
      </div>
    );

  return heading;
};

export default Heading;
