export function Icon({
  height = "3.125rem",
  width = "3.125rem",
  color = "#0096FA",
  url,
  onPress = () => {},
  customClass = "",
}) {
  return (
    <div
      onClick={onPress}
      style={{
        cursor: onPress !== undefined ? "pointer" : "",
        backgroundColor: color,
        mask: `url(${url}) no-repeat center`,
        maskImage: `url(${url})`,
        WebkitMask: `url(${url}) no-repeat center`,
        maskRepeat: "no-repeat",
        WebkitMaskRepeat: "no-repeat",
        height,
        width,
        WebkitMaskSize: `${width} ${height}`,
      }}
      role="presentation"
      className={customClass}
    />
  );
}

Icon.displayName = "Icon";
