import { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import LogoIn from "./IndividualAnimations/LogoIn.json";
import CircleAnim from "./IndividualAnimations/Circle.json";
import LogoOut from "./IndividualAnimations/LogoOut.json";
import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
`;

export default function DocSperaLoading(props) {
  const { isDataLoading, setPageLoaded, height, width } = props;
  const [loading, setLoading] = useState(false);
  const [currentAnim, setCurrentAnim] = useState(LogoOut);
  // const [pageLoaded, setPageLoaded] = useState(false);
  const animationRef = useRef(null);

  // This is the documentation for lottie-react: https://github.com/Gamote/lottie-react

  /*
  These are the main comments: the setPageLoaded state would be called to set the value
  to true so that the animation circle finishes, then leads into the logo appearing, then
  the page is loaded for users (per comments from Luca)
  */

  useEffect(() => {
    if (!loading && isDataLoading) {
      setPageLoaded();
    }
  }, [isDataLoading, loading]);

  const circleAnim = () => {
    setCurrentAnim(CircleAnim);
    setLoading(true);
  };

  const lastAnimHandler = () => {
    setCurrentAnim(LogoIn);
    setLoading(false);
  };

  return (
    <div
      id="docspera-loading"
      style={{ height: height ?? "100px", width: width ?? "100px" }}
    >
      {/* <Lottie animationData={fullAnim} /> : The full Animation */}
      <Lottie
        onDOMLoaded={() => animationRef.current?.setSpeed(2)}
        lottieRef={animationRef}
        animationData={currentAnim}
        loop={loading}
        onComplete={() => !isDataLoading && circleAnim()}
        onLoopComplete={() => isDataLoading && lastAnimHandler()}
      />
    </div>
  );
}
