import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "../../components/NavBar/NavBar";
import Calendar from "../Calendar/Calendar";
import { Provider as AuthProvider } from "../../context/AuthContext";
import { Provider as GlobaleProvider } from "../../context/GlobalContext";
import { Provider as EventProvider } from "../../context/EventContext";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <AuthProvider>
      <GlobaleProvider>
        <EventProvider>
          <Toaster position="top-right" />
          <div className="App">
            <NavBar />
            <Routes>
              <Route path="/" element={<Calendar />} />
            </Routes>
          </div>
        </EventProvider>
      </GlobaleProvider>
    </AuthProvider>
  );
}

export default App;
