import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import styled from "styled-components";
import ArrowIcon from "../../assets/arrow.svg";
import { Icon } from "../Icon/Icon";

const Calendar = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  position: fixed;
  z-index: 1;
`;

const Month = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0096fa;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-size: 18px;
  color: #ffffff;
  padding-top: 10px;
  position: relative;

  #month {
    display: inline-flex;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }

  #year {
    display: inline;
    margin-right: 5px;
  }

  .select-all {
    position: absolute;
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
    left: 0px;
  }

  .arrow-box {
    display: inline-flex;
    width: 25px;
    height: 25px;
    background-color: none;
    border-radius: 50%;
    cursor: pointer;

    .right-arrow {
      margin: auto;
      filter: brightness(0) invert(1);
      transform: scale(0.88);
      transform-origin: 180% 50% 0;
    }

    .left-arrow {
      margin: auto;
      filter: brightness(0) invert(1);
      rotate: 180deg;
      transform: scale(0.88);
      transform-origin: 40% 50% 0;
    }
  }

  .arrow-box:hover {
    background-color: #ffffff;

    .right-arrow,
    .left-arrow {
      filter: invert(0);
    }
  }

  .disabled-bg:not(.right-arrow) {
    &:hover {
      background-color: transparent;
      cursor: default;

      .lower-opacity {
        filter: brightness(0) invert(1);
      }
    }

    .lower-opacity {
      cursor: default !important;
      opacity: 0.3;
    }
  }
`;

const Table = styled.table`
  thead tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .week-day {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      background-color: #0096fa;
      width: 100%;
      padding: 10px 0;
      font-weight: normal;
    }
  }
`;

const TBody = styled.tbody`
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 2px 5px;
    border-radius: 5px;
    

    //calendar week hover
    &.week-row {
      &:hover {
        background-color: #b1e0ff;
        cursor: pointer;

        .current-month-day,
        .beyond-month-days,
        .current-month-today {
          .week {
            &.start-week {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &.end-week {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }



    //all calendar days
    .current-month-day,
    .beyond-month-days,
    .current-month-today {
      .day,
      .week {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: 16px;

      }

      .day {
        &.selected {
          .text {
            background-color: #0096fa;
            color: #ffffff;
          }
        }

        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 85%;
          width: 85%;
          border-radius: 100%;
          cursor: pointer;

          &:hover {
            background-color: #f1f1f1;
          }
        }

        .transparent-bg {
          &:hover {
            background-color: transparent;
            cursor: default;
          }
        }
      }

      .week {
        &.selected {
          background-color: #0096fa;


          &.start-week {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &.end-week {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          .text {
            color: #ffffff;
          }
        }
      }
    }

    //today
    .current-month-today {
      .day {
          .text {
            border: 2px solid #e23118;
          }
  
          &.selected {
            .text {
              background-color: #002855;
  
              &:hover {
                background-color: #f1f1f1;
                color: #002855;
              }
            }
          }
        }

      .week {
        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 85%;
          width: 85%;
          border: 1px solid #e23118;
          border-radius: 50%;
        }
      }
    }

    // other month days
    .beyond-month-days {
      .day .text,
      .week .text {
        color: #C6C6C6;
    }

    //current month days
    .current-month-day {
      .day {
        .text {
          color: #002855;
        }
      }
    }
  }
`;

function CalendarPicker(props) {
  const { value, selectBy, handleSelect, isOpen, setIsOpen, handleClear } =
    props;
  const weekDayName = moment.weekdaysShort();
  const [currentMonth, setCurrentMonth] = useState(
    value ? moment(value).format("YYYYMM") : moment().format("YYYYMM")
  );

  useEffect(() => {
    value
      ? setCurrentMonth(moment(value).format("YYYYMM"))
      : setCurrentMonth(moment().format("YYYYMM"));
  }, [value]);

  const onClickChangeMonth = (delta) => {
    const newDate = moment(currentMonth).add(delta, "month").format("YYYYMM");
    setCurrentMonth(newDate);
  };

  const calculateCalendar = (currMonth) => {
    const today = moment();

    // get the first day of the month
    const firstDayOfMonth = moment(currMonth).startOf("month");

    // get the last day of the month
    const lastDayOfMonth = moment(currMonth).endOf("month");

    // get the first day of the week that contains the first day of the month
    const firstDayOfWeek = moment(firstDayOfMonth).startOf("week");

    // get the last day of the week that contains the last day of the month
    const lastDayOfWeek = moment(lastDayOfMonth).endOf("week");

    const findIsSelected = (day) => {
      if (selectBy === "day") {
        return moment(value).isSame(day, "day");
      }
      if (selectBy === "week" && value) {
        const startOfWeek = moment(value).startOf("week");
        const endOfWeek = moment(value).endOf("week");
        return moment(day, "day").isBetween(startOfWeek, endOfWeek, null, "[]");
      }
      return false;
    };

    let day = moment(firstDayOfWeek);
    const weeks = [[]];

    let i = 0;
    while (day.isSameOrBefore(lastDayOfWeek)) {
      const weekNum = Math.floor(i / 7);

      if (weeks[weekNum] === undefined) {
        weeks[weekNum] = [];
      }

      const date = day.toDate();
      const dayObj = {
        date,
        isCurrentMonth: day.isSame(firstDayOfMonth, "month"),
        isToday: day.isSame(today, "day"),
        isSelected: findIsSelected(day),
        isDisabled: false, // TODO: implement this
        isWeekend: day.day() === 0 || day.day() === 6,
        isStartWeek: day.day() === 0,
        isEndWeek: day.day() === 6,
      };

      weeks[weekNum].push(dayObj);
      day = day.add(1, "day");
      i += 1;
    }

    return weeks;
  };

  return (
    <Calendar>
      <Month>
        {handleClear && (
          <div
            className="select-all"
            onClick={() => {
              handleClear();
              setIsOpen(!isOpen);
            }}
          >
            Select All
          </div>
        )}
        <div
          className={`arrow-box ${
            props.onlyAfterDate &&
            moment(props.onlyAfterDate).isSameOrAfter(currentMonth, "month")
              ? "disabled-bg"
              : ""
          }`}
          onClick={() =>
            props.onlyAfterDate &&
            moment(props.onlyAfterDate).isSameOrAfter(currentMonth, "month")
              ? undefined
              : onClickChangeMonth(-1)
          }
          role="button"
          tabIndex={0}
        >
          <Icon
            url={ArrowIcon}
            height="18px"
            width="9px"
            color="#0096fa"
            customClass={`left-arrow ${
              props.onlyAfterDate &&
              moment(props.onlyAfterDate).isSameOrAfter(currentMonth, "month")
                ? "lower-opacity"
                : ""
            }`}
          />
        </div>
        <div id="month">{moment(currentMonth).format("MMM")}</div>
        <div id="year">{moment(currentMonth).format("YYYY")}</div>
        <div
          className="arrow-box"
          onClick={() => onClickChangeMonth(1)}
          role="button"
          tabIndex={0}
        >
          <Icon
            url={ArrowIcon}
            height="18px"
            width="9px"
            color="#0096fa"
            customClass="right-arrow"
          />
        </div>
      </Month>
      <Table>
        <thead>
          <tr>
            {weekDayName.map((day) => (
              <th key={day} className="week-day">
                {day.toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <TBody>
          {calculateCalendar(currentMonth).map((week, week_idx) => {
            return (
              <tr
                key={week[week_idx].date.toDateString()}
                className={`${selectBy === "day" ? "day" : "week"}-row`}
              >
                {week.map((day) => {
                  return (
                    <td
                      key={day.date.getUTCDate()}
                      className={
                        day.isCurrentMonth
                          ? // && moment(props.onlyAfterDate).isSameOrBefore(day.date)
                            day.isToday
                            ? "current-month-today"
                            : "current-month-day"
                          : "beyond-month-days"
                      }
                    >
                      <div
                        className={`${selectBy === "day" ? "day" : "week"} ${
                          day.isSelected && "selected"
                        } ${day.isStartWeek && "start-week"} ${
                          day.isEndWeek && "end-week"
                        }`}
                        onClick={() => {
                          props.onlyAfterDate
                            ? moment(props.onlyAfterDate).isSameOrBefore(
                                day.date
                              ) && handleSelect(day.date, selectBy)
                            : handleSelect(day.date, selectBy);
                          setIsOpen(!isOpen);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <p
                          className={`text ${
                            !moment(props.onlyAfterDate).isSameOrBefore(
                              day.date
                            )
                              ? "transparent-bg"
                              : ""
                          }`}
                        >
                          {day.date.getDate()}{" "}
                        </p>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </TBody>
      </Table>
    </Calendar>
  );
}

export default CalendarPicker;
