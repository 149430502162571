import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/Close.svg";
import { Icon } from "../Icon/Icon";

const ModalBg = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;

  .custom-modal {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    z-index: 9995;
    box-shadow: 0 4px 16px RGBa(0, 0, 0, 0.4);
    max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : "50%")};

    .blue-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h4 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        font-family: "Open Sans", sans-serif;
        font-size: 26px;
        color: #0096fa;
        font-weight: 500;
        line-height: 28px;

        span {
          margin-left: 10px;
          color: #000000;
        }
      }

      .modal-icons {
        display: flex;
        gap: 20px;

        .added-icons {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }

      .close-icon {
        cursor: pointer;
      }
    }
  }
`;

function Modal({
  title,
  subTitle,
  content,
  toggleModal,
  showtimesIcon = false,
  maxWidth,
  icons,
}) {
  const KEY_NAME_ESC = "Escape";
  const KEY_EVENT_TYPE = "keyup";

  const handleEscKey = useCallback(
    (event) => {
      if (event.key === KEY_NAME_ESC) {
        toggleModal();
      }
    },
    [toggleModal]
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

  return (
    <ModalBg
      $maxWidth={maxWidth}
      onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
    >
      <div
        className="custom-modal"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="blue-header">
          {title && (
            <h4>
              {title}
              {subTitle && <span>{subTitle}</span>}
            </h4>
          )}
          <div className="modal-icons">
            {icons &&
              icons.map((icon) => (
                <img
                  key={icon.url}
                  src={icon.url}
                  className="added-icons"
                  width={icon.width || 20}
                  height={icon.height || 20}
                  onClick={icon.clickedFunction}
                  alt={icon.alt}
                />
              ))}
            {showtimesIcon && (
              <Icon
                url={CloseIcon}
                customClass="close-icon"
                width="20px"
                height="20px"
                color="#000000"
                onPress={() => toggleModal()}
                alt="times-icon"
              />
            )}
          </div>
        </div>
        {content}
      </div>
    </ModalBg>
  );
}

export default Modal;
